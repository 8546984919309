import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Home from '../pages/home/index';
import AboutTwo from '../pages/about-2';

import Post from "../pages/blog/post";
import Evento from "../pages/blog/evento";
import Pagina from "../pages/course/course-single/index";

import Scientifico from "../pages/course/course-single/scientifico";
import Amministrazione from "../pages/course/course-single/amministrazione";
import Umane from "../pages/course/course-single/umane";
import Sociale from "../pages/course/course-single/sociale";
import Linguistico from "../pages/course/course-single/linguistico";
import Applicate from "../pages/course/course-single/applicate";
import Madeinitaly from "../pages/course/course-single/madeinitaly";

import Blog from "../pages/blog";
import Event from "../pages/event";
import Error from "../pages/404";
import LoadTop from '../components/Common/ScrollTop/LoadTop'

const paths = [
  "/scuola/contatti",
  "/servizi/navetta",
  "/servizi/eventi",
  "/servizi/bar",
  "/servizi/parking",
  "/didattica/tutoraggio",
  "/didattica/modulistica-studenti",
  "/successo/dada",
  "/orientamento/didattico",
  "/orientamento/uscita",
  "/orientamento/sportello",
  "/orientamento/personalizza",
  "/didattica/inclusione",
  "/didattica/regolamento",
  "/didattica/patto",
  "/didattica/ptof",
  "/didattica/rav",
  "/didattica/pcto",
  "/didattica/consiglio",
  "/didattica/ricevimento",
  "/didattica/strumentali",
  "/scuola/sede",
  "/location/parco",
  "/location/lab",
  "/didattica/openair",
  "/scuola/trasparente",
  "/scuola/servizi",
  "/scuola/presentazione",
  "/lavora",
  "/itsmoda",
  "/privacy",
  "/iscrizioni/iscrizioni-online",
  "/iscrizioni/recupero",
  "/registro",
];


const App = () => {
    return (
      <div className="App">
        <Router>
          <LoadTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about-2" component={AboutTwo} />

            <Route path="/corsi/scientifico" component={Scientifico} />
            <Route path="/corsi/amministrazione" component={Amministrazione} />
            <Route path="/corsi/umane" component={Umane} />
            <Route path="/corsi/applicate" component={Applicate} />
            <Route path="/corsi/linguistico" component={Linguistico} />
            <Route path="/corsi/sociale" component={Sociale} />
            <Route path="/corsi/madeitaly" component={Madeinitaly} />

            {paths.map((path, index) => (
              <Route key={index} path={path} component={Pagina} />
            ))}
            <Route path="/event" component={Event} />
            <Route exact path="/blog" component={Blog} />
            <Route path="/blog/:slug" component={Post} />
            <Route path="/eventi/:slug" component={Evento} />
            <Route component={Error} />
          </Switch>
        </Router>
      </div>
    );
}

export default App;
