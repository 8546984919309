import React, { useState, useEffect } from "react";

import icon1 from "../../assets/img/choose/home12/icon/1.png";
import icon2 from "../../assets/img/choose/home12/icon/2.png";
import icon3 from "../../assets/img/choose/home12/icon/3.png";

import bgImg from "../../assets/img/bg/home12/why-learn-bg.jpg";

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const WhyChooseUs = () => {
  
  const [faqData, setfaqData] = useState({
    risposta1: "",
    risposta2: "",
    risposta3: "",
    Titolo1: "",
    Titolo2: "",
    Titolo3: "",
  });

  const fetchfaqData = async () => {
    // Definisci la query GraphQL
    const query = `{
  pageBy(pageId: 209) {
    faq {
      risposta1
      risposta2
      risposta3
      titolo1
      titolo2
      titolo3
    }
  }
}`;

    try {
      // Esegui la richiesta all'endpoint GraphQL di WordPress
      const response = await fetch(
        "https://gestione.istitutopoliziano.it/homo",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query }),
        }
      );

      // Estrai i dati dalla risposta
      const result = await response.json();

      if (response.ok) {
        // Imposta i dati nello stato
        setfaqData(result.data?.pageBy.faq);
      } else {
        console.error("Errore nella richiesta GraphQL:", result.errors);
      }
    } catch (error) {
      console.error("Errore nella richiesta di rete:", error);
    }
  };

  useEffect(() => {
    fetchfaqData();
  }, []);

  if (!faqData) {
    return <div>Caricamento in corso...</div>;
  }

  const titolo1 = faqData ? faqData.titolo1 : "";
  const titolo2 = faqData ? faqData.titolo2 : "";
  const titolo3 = faqData ? faqData.titolo3 : "";
  const risposta1 = faqData ? faqData.risposta1 : "";
  const risposta2 = faqData ? faqData.risposta2 : "";
  const risposta3 = faqData ? faqData.risposta3 : "";

  return (
    <div className="why-choose-us style3" style={bgStyle}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 md-mb-40">
            <div className="img-part">
              <a href="https://www.itsacademymoda.it/">
                <img
                  src="https://www.itsacademymoda.it/wp-content/uploads/2023/08/perchescegliere-e1691407285828.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="col-lg-6 pl-60 md-pl-14">
            <div className="sec-title3 mb-30">
              <h2 className=" title new-title margin-0 pb-14">
                ITS ACADEMY SISTEMA MODA
              </h2>
              <div className="new-desc">
                Il Poliziano è socio fondatore del ITS ACADEMY MODA
              </div>
            </div>
            <div className="services-part mb-20">
              <div className="services-icon">
                <img src={icon1} alt="" />
              </div>
              <div className="services-text">
                <h2 className="title"> {titolo1}</h2>
                <p className="services-txt"> {risposta1}</p>
              </div>
            </div>
            <div className="services-part mb-20">
              <div className="services-icon">
                <img src={icon2} alt="" />
              </div>
              <div className="services-text">
                <h2 className="title"> {titolo2}</h2>
                <p className="services-txt"> {risposta2}</p>
              </div>
            </div>
            <div className="services-part">
              <div className="services-icon">
                <img src={icon3} alt="" />
              </div>
              <div className="services-text">
                <h2 className="title">{titolo3}</h2>
                <p className="services-txt"> {risposta3}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
