import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';

import footerLogo1 from '../../../assets/img/logo/logo.png';


const Footer = (props) => {
    const { footerLogo, footerClass, footerTopClass } = props;
    return (
      <footer className={footerClass ? footerClass : "rs-footer"}>
        <div className={`footer-top ${footerTopClass}`}>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
                <div className="footer-logo mb-30">
                  <Link to="/" as="/">
                    <img
                      src={footerLogo ? footerLogo : footerLogo1}
                      alt="Logo"
                    />
                  </Link>
                </div>
                <div className="textwidget pr-60 md-pr-14">
                  <p>
                    Sedut perspiciatis unde omnis iste natus error sitlutem acc
                    usantium doloremque denounce with illo inventore veritatis
                  </p>
                </div>
                <ul className="footer_social">
                  <li>
                    <a href="/">
                      <i className="fa fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-google-plus"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
                <h3 className="widget-title">Indirizzo</h3>
                <ul className="address-widget">
                  <li>
                    <i className="flaticon-location"></i>
                    <div className="desc">
                      Via della Travicella, 55/61 - 00179 Roma
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-call"></i>
                    <div className="desc">
                      <a href="tel:+39065110121">06 5110 121</a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-email"></i>
                    <div className="desc">
                      <a
                        href="mailto:istituto.poliziano@virgilio.it
"
                      >
                        istituto.poliziano@virgilio.it
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 pl-50 md-pl-14 footer-widget md-mb-50">
                <h3 className="widget-title">Corsi</h3>
                <ul className="site-map">
                  <li>
                    <Link to="/corsi/scientifico">Liceo Scientifico</Link>
                  </li>
                  <li>
                    <Link to="/corsi/applicate">
                      Liceo Scientifico - Opzione Scienze Applicate
                    </Link>
                  </li>
                  <li>
                    <Link to="/corsi/umane">
                      Liceo delle Scienze Umane
                    </Link>
                  </li>
                  <li>
                    <Link to="/corsi/linguistico">Liceo Linguistico</Link>
                  </li>
                  <li>
                    <Link to="/corsi/sociale">
                      Liceo delle Scienze Umane opz. Economico-Sociale
                    </Link>
                  </li>
                  <li>
                    <Link to="/corsi/amministrazione">
                      Amministrazione, Finanza, Marketing
                    </Link>
                  </li>
                </ul>
              </div>
             
            </div>
          </div>
        </div>
        <FooterBottom />
      </footer>
    );
}

export default Footer;