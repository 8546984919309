import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu, parentMenux } = props;

    const location = useLocation(); 

    return (
      <React.Fragment>
        <li>
          <Link to="/" id={parentMenux === "homex" ? "active-menu-bis" : ""}>
            Home
          </Link>
        </li>
        <li
          className={
            parentMenu === 583
              ? "menu-item-has-children current-menu-item"
              : "menu-item-has-children"
          }
        >
          <Link to="#" className={parentMenu === 583 ? "active-menu" : ""}>
            La Scuola
          </Link>
          <ul className="sub-menu">
            <li>
              <Link
                to="/scuola/presentazione"
                className={
                  location.pathname === "/scuola/presentazione"
                    ? "active-menu"
                    : ""
                }
              >
                Presentazione
              </Link>
            </li>
            <li>
              <Link
                to="/scuola/sede"
                className={
                  location.pathname === "/scuola/sede" ? "active-menu" : ""
                }
              >
                Sede
              </Link>
            </li>
            <li>
              <Link
                to="/scuola/servizi"
                className={
                  location.pathname === "/scuola/servizi" ? "active-menu" : ""
                }
              >
                Servizi
              </Link>
            </li>
            <li>
              <Link
                to="/scuola/contatti"
                className={
                  location.pathname === "/scuola/contatti" ? "active-menu" : ""
                }
              >
                Contatti Segreteria
              </Link>
            </li>
            <li>
              <Link
                to="/scuola/trasparente"
                className={
                  location.pathname === "/scuola/trasparente"
                    ? "active-menu"
                    : ""
                }
              >
                Amministrazione trasparente
              </Link>
            </li>
          </ul>
        </li>
        <li
          className={
            parentMenu === 96
              ? "menu-item-has-children current-menu-item"
              : "menu-item-has-children"
          }
        >
          <Link to="#" className={parentMenu === 96 ? "active-menu" : ""}>
            Corsi di studio
          </Link>
          <ul className="sub-menu">
            <li>
              <Link
                to="/corsi/scientifico"
                className={
                  location.pathname === "/corsi/scientifico"
                    ? "active-menu"
                    : ""
                }
              >
                Liceo Scientifico
              </Link>
            </li>
            <li>
              <Link
                to="/corsi/applicate"
                className={
                  location.pathname === "/corsi/applicate" ? "active-menu" : ""
                }
              >
                Liceo Scientifico - Opzione Scienze Applicate
              </Link>
            </li>
            <li>
              <Link
                to="/corsi/umane"
                className={
                  location.pathname === "/corsi/umane" ? "active-menu" : ""
                }
              >
                Liceo delle Scienze Umane
              </Link>
            </li>
            <li>
              <Link
                to="/corsi/linguistico"
                className={
                  location.pathname === "/corsi/linguistico"
                    ? "active-menu"
                    : ""
                }
              >
                Liceo Linguistico
              </Link>
            </li>
            <li>
              <Link
                to="/corsi/sociale"
                className={
                  location.pathname === "/corsi/sociale" ? "active-menu" : ""
                }
              >
                Liceo delle Scienze Umane opz. Economico-Sociale
              </Link>
            </li>
            <li>
              <Link
                to="/corsi/amministrazione"
                className={
                  location.pathname === "/corsi/amministrazione"
                    ? "active-menu"
                    : ""
                }
              >
                Amministrazione, Finanza, Marketing
              </Link>
            </li>
            <li>
              <Link
                to="/corsi/madeitaly"
                className={
                  location.pathname === "/corsi/madeitaly" ? "active-menu" : ""
                }
              >
                Liceo Made in Italy
              </Link>
            </li>
          </ul>
        </li>

        <li
          className={
            parentMenu === 102
              ? "menu-item-has-children current-menu-item"
              : "menu-item-has-children"
          }
        >
          <Link to="#" className={parentMenu === 102 ? "active-menu" : ""}>
            Didattica
          </Link>
          <ul className="sub-menu">
            <li>
              <Link to="#" className={parentMenu === 102 ? "active-menu" : ""}>
                Documenti
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link
                    to="/didattica/regolamento"
                    className={
                      location.pathname === "/didattica/regolamento"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Regolamento di istituto
                  </Link>
                </li>
                <li>
                  <Link
                    to="/didattica/rav"
                    className={
                      location.pathname === "/didattica/rav"
                        ? "active-menu"
                        : ""
                    }
                  >
                    RAV
                  </Link>
                </li>
                <li>
                  <Link
                    to="/didattica/ptof"
                    className={
                      location.pathname === "/didattica/ptof"
                        ? "active-menu"
                        : ""
                    }
                  >
                    PTOF
                  </Link>
                </li>
                <li>
                  <Link
                    to="/didattica/patto"
                    className={
                      location.pathname === "/didattica/patto"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Patto di corrensponsabilità
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/didattica/strumentali"
                className={
                  location.pathname === "/didattica/strumentali"
                    ? "active-menu"
                    : ""
                }
              >
                Figure strumentali
              </Link>
            </li>
            <li>
              <Link
                to="/didattica/pcto"
                className={
                  location.pathname === "/didattica/pcto" ? "active-menu" : ""
                }
              >
                PCTO
              </Link>
            </li>
            <li>
              <Link to="#" className={parentMenu === 155 ? "active-menu" : ""}>
                Orientamento
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link
                    to="/orientamento/didattico"
                    className={
                      location.pathname === "/orientamento/didattico"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Orientamento didattico trasversale
                  </Link>
                </li>
                <li>
                  <Link
                    to="/orientamento/uscita"
                    className={
                      location.pathname === "/orientamento/uscita"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Orientamento in uscita
                  </Link>
                </li>
                <li>
                  <Link
                    to="/orientamento/sportello"
                    className={
                      location.pathname === "/orientamento/sportello"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Sportello ascolto
                  </Link>
                </li>
                <li>
                  <Link
                    to="/orientamento/personalizza"
                    className={
                      location.pathname === "/orientamento/personalizza"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Personalizzazione didattica
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/didattica/tutoraggio"
                className={
                  location.pathname === "/didattica/tutoraggio"
                    ? "active-menu"
                    : ""
                }
              >
                Tutoraggio online
              </Link>
            </li>
            <li>
              <Link
                to="/didattica/openair"
                className={
                  location.pathname === "/didattica/openair"
                    ? "active-menu"
                    : ""
                }
              >
                Lezioni all'aperto
              </Link>
            </li>
            <li>
              <Link
                to="/didattica/ricevimento"
                className={
                  location.pathname === "/didattica/ricevimento"
                    ? "active-menu"
                    : ""
                }
              >
                Orari di ricevimento
              </Link>
            </li>
            <li>
              <Link
                to="/didattica/modulistica-studenti"
                className={
                  location.pathname === "/didattica/modulistica-studenti"
                    ? "active-menu"
                    : ""
                }
              >
                Modulistica studenti
              </Link>
            </li>
            <li>
              <Link
                to="/blog"
                className={location.pathname === "/blog" ? "active-menu" : ""}
              >
                Giornalino scolastico
              </Link>
            </li>
          </ul>
        </li>

        <li
          className={
            parentMenu === 98 || parentMenu === 155
              ? "menu-item-has-children current-menu-item"
              : "menu-item-has-children"
          }
        >
          <Link
            to="#"
            className={
              parentMenu === 205 || parentMenu === 205 ? "active-menu" : ""
            }
          >
            Iscrizioni
          </Link>
          <ul className="sub-menu">
            <li>
              <Link
                to="/iscrizioni/iscrizioni-online"
                className={
                  location.pathname === "/iscrizioni/iscrizioni-online"
                    ? "active-menu"
                    : ""
                }
              >
                Iscrizioni A.S. 2024/2025
              </Link>
            </li>
            <li>
              <Link
                to="/iscrizioni/recupero"
                className={
                  location.pathname === "/iscrizioni/recupero"
                    ? "active-menu"
                    : ""
                }
              >
                Recupero anni scolastici
              </Link>
            </li>
          </ul>
        </li>

        <li
          className={
            parentMenu === 100 || parentMenu === 157 || parentMenu === 159
              ? "menu-item-has-children current-menu-item"
              : "menu-item-has-children"
          }
        >
          <Link
            to="#"
            className={
              parentMenu === 100 || parentMenu === 157 || parentMenu === 159
                ? "active-menu"
                : ""
            }
          >
            Poliziano informa
          </Link>
          <ul className="sub-menu">
            <li>
              <Link
                to="/event"
                className={location.pathname === "/event" ? "active-menu" : ""}
              >
                Eventi
              </Link>
            </li>
            <li>
              <Link
                to="/lavora"
                className={location.pathname === "/lavora" ? "active-menu" : ""}
              >
                Lavora con noi
              </Link>
            </li>
          </ul>
        </li>
      </React.Fragment>
    );
}

export default MenuItems;